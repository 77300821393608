(function () {
    'use strict';

    angular.module('backoffice.services')
        .factory('Categories', categoriesService);

    function categoriesService($http) {
        var _service = {
            getAll: getAllFunction,
            getAllActive: getAllActiveFunction,
            store: storeFunction,
            edit: editFunction,
            delete: deleteFunction,
            changeStatus: changeStatusFunction,
            getPage: getPageFunction,
            getOne: getOneFunction,
            getNavItemsPerPage: getNavItemsPerPage
        };

        return _service;

        function getAllFunction() {
            return $http.get('/backoffice/v1/categories');
        }

        function getOneFunction(id) {
            return $http.get('/backoffice/v1/categories/' + id + '');
        }

        function getAllActiveFunction() {
            return $http.get('/backoffice/v1/categories/active');
        }

        function storeFunction(categories) {
            return $http.post('/backoffice/v1/categories', categories);
        }

        function editFunction(categories) {
            return $http.put('/backoffice/v1/categories', categories);
        }

        function deleteFunction(categories) {
            return $http.delete('/backoffice/v1/categories/' + categories._id);
        }

        function changeStatusFunction(categories) {
            return $http.put('/backoffice/v1/categories/' + categories._id);
        }

        function getPageFunction(info) {
            return $http.post('/backoffice/v1/categories/getpage', info);
        }

        function getNavItemsPerPage() {
            return 10;
        }
    }
})();