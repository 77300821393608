(function() {
  'use strict';
  angular.module('backoffice.base')
    .controller('RegisterCtrl', registerCtrl);

  function registerCtrl($rootScope, $location, Auth, SweetAlert) {
    var vm = this;

    vm.role = Auth.userRoles.user;
    vm.userRoles = Auth.userRoles;
    vm.register = registerFunction;
      
    function registerFunction() {
      $rootScope.loading = true;
      Auth.register({
        email: vm.email,
        password: vm.password,
        role: vm.role
      }).then(function() {
        $rootScope.loading = false;
        $location.path('/users');
      }, function(err) {
        $rootScope.loading = false;
        SweetAlert.swal('Error', JSON.stringify(err), 'error');
      });
    }
  }
})();
