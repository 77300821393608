(function () {
    'use strict';

    angular.module('backoffice.services')
        .factory('Slides', slidesService);

    function slidesService($http) {
        var _service = {
            getAll: getAllFunction,
            getAllActive: getAllActiveFunction,
            store: storeFunction,
            edit: editFunction,
            delete: deleteFunction,
            changeStatus: changeStatusFunction,
            getPage: getPageFunction,
            getNavItemsPerPage:getNavItemsPerPage
        };

        return _service;

        function getAllFunction() {
            return $http.get('/backoffice/v1/slides');
        }

        function getAllActiveFunction() {
            return $http.get('/backoffice/v1/slides/active');
        }

        function storeFunction(slides) {
            return $http.post('/backoffice/v1/slides', slides);
        }


        function editFunction(slides) {
            return $http.put('/backoffice/v1/slides', slides);
        }

        function deleteFunction(slides) {
            return $http.delete('/backoffice/v1/slides/' + slides._id);
        }

        function changeStatusFunction(slides) {
            return $http.put('/backoffice/v1/slides/' + slides._id);
        }

        function getPageFunction(info, id) {
            info._myid = id;
            return $http.post('/backoffice/v1/slides/getpage', info);
        }
 function getNavItemsPerPage() {
            return 10;
        }

    }
})();