(function() {
  'use strict';

  angular.module('backoffice.tests', [])
    .config(configFunction);

  function configFunction($stateProvider) {
    $stateProvider
      .state('admin.tests', {
        url: '/courses/:id/tests',
        templateUrl: 'tests/views/tests.html',
        controller: 'TestsCtrl',
        controllerAs: 'vm',
                resolve: {
                    objDataResolve: getAllData
                }

      });
  }
  function getAllData($log, $rootScope, $stateParams, Tests, SweetAlert) {

        var vm = this;
        vm.myid = $stateParams.id;
        $rootScope.loading = true;

        vm.itemsPerPage = Tests.getNavItemsPerPage();

        var obj = {
            pagination: {
                start: 0,
                totalItemCount: 0,
                number: vm.itemsPerPage
            },
            group: "active",
            sort: {},
            search: {}
        }

        return Tests.getPage(obj,vm.myid )
            .then(function (resp) {
                $rootScope.loading = false;
                return resp.data.data;
            }, function (error) {
                $log.info("error orders");
                $rootScope.loading = false;
                SweetAlert.swal('Errore', 'Error in donwload the data', 'error');
            });

    }
})();
