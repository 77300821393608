(function () {

    'use strict';

    angular.module('backoffice.slides')
        .controller('ActvitiesCtrl', homeCtrl);

    function homeCtrl(objDataResolve, $rootScope, Users, Auth, Tracks, SweetAlert, $stateParams) {
        var vm = this;
        vm.itemsByPage = 10;
        vm.currentPage = 0;
        vm.total = objDataResolve.total;
        vm.numberOfPages = Math.ceil(vm.total / vm.itemsByPage);
        vm._doit = false;
        vm.myid = $stateParams.id;
        vm.tableStateSaved = {};
        vm.tableStateSaved.pagination = {};
        vm.tableStateSaved.pagination.start = 0;
        vm.tableStateSaved.pagination.totalItemCount = 0;
        vm.tableStateSaved.pagination.number = vm.itemsByPage;
        vm.tableStateSaved.sort = {};
        vm.tableStateSaved.search = {};
        vm.makeRequest = makeRequestFunction;
        vm.saveQuery = saveQueryFunction;
        vm.getPage = getPageFunction;

        $rootScope.makePagination = makePaginationFunction;
        $rootScope.loading = true;
        $rootScope.page_title = 'Last Activities';

        getPageFunction();

        function makeRequestFunction() {
            vm._doit = true;
        }

        function saveQueryFunction(tableState) {
            vm.tableStateSaved = tableState;
            if (vm._doit) {
                vm.getPage();
                vm._doit = false;
            }
        }

        function getPageFunction(currentPage) {
            vm.currentPage = currentPage ? currentPage : 0;
            vm.tableStateSaved.pagination = {
                   start: currentPage *vm.itemsByPage,
                number: vm.itemsByPage
            }
            $rootScope.loading = true;
            Tracks.getPage(vm.tableStateSaved, vm.myid).then(function (resp) {
                $rootScope.loading = false;
                vm.info = resp.data.data.result;
                vm.total = resp.data.data.total;
                var toCeil = resp.data.data.total / vm.itemsByPage;
                vm.tableStateSaved.pagination.numberOfPages = Math.ceil(toCeil);
                vm.numberOfPages = Math.ceil(toCeil);
            }, function () {
                $rootScope.loading = true;
                $rootScope.error = 'Failed to fetch push';
            });
        }

        function makePaginationFunction(page) {
            $rootScope.currentPage = page;
            page--;
            vm.tableStateSaved.pagination.start = (page * vm.itemsByPage);
            vm.getPage();
        }
    }
})();