(function () {
    'use strict';

    angular.module('backoffice.services')
        .factory('Analitycs', slidesService);

    function slidesService($http) {
        var _service = {
            getPage: getPageFunction,
            getUserPage: getUserPageFunction,
            registrations: registrationsFunction,
            getForUser: getForUserFunction,
            getSessionCsv: getSessionCsvFunction,
            getUserCsv: getUserCsvFunction,
            getForCategory: getForCategoryFunction,
            getCategoryCsv: getCategoryCsvFunction,
            getNavItemsPerPage: getNavItemsPerPage
        };

        return _service;

        function getPageFunction(info, id) {
            info._myid = id;
            return $http.post('/backoffice/v1/course/analitycs/getpage', info);
        }

        function getUserPageFunction(info, id) {
            info._myid = id;
            return $http.post('/backoffice/v1/course/analitycs/getuserpage', info);
        }

        function registrationsFunction() {
            return $http.get('/backoffice/v1/course/analitycs/registrations');
        }

        function getForUserFunction(info, id) {
            info.userId = id;
            return $http.post('/backoffice/v1/course/analitycs/getforuser', info);
        }

        function getSessionCsvFunction(id) {
            var info = {
                _myid: id
            };
            return $http.post('/backoffice/v1/course/analitycs/getcsv', info);
        }

        function getUserCsvFunction(id) {
            var info = {
                _myid: id
            };
            return $http.post('/backoffice/v1/course/analitycs/getcsvuser', info);
        }

        function getForCategoryFunction(info, id) {
            info._myid = id;
            return $http.post('/backoffice/v1/course/analitycs/getforcategory', info);
        }

        function getCategoryCsvFunction(id) {
            var info = {
                _myid: id
            };
            return $http.post('/backoffice/v1/course/analitycs/getcsvcategory', info);
        }

        function getNavItemsPerPage() {
            return 10;
        }
    }

})();