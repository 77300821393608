(function() {
  'use strict';
  angular.module('backoffice')
    .run(runFunction);

  function runFunction($rootScope, $state, Auth,SweetAlert) {
    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState) {
      if (!Auth.authorize(toState.data.access)) {
        if (fromState.url !== '^') {
          SweetAlert.swal('Error', 'You are not authorized.', 'error');
        }
        event.preventDefault();
        if (Auth.isLoggedIn())
          $state.go('user.home');
        else {
          $state.go('anon.login');
        }
      }
    });
  }
})();
