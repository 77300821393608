(function() {

  'use strict';

  angular.module('backoffice.categories')
    .controller('CategoriesCreateCtrl', categoriesCreateCtrl);

  function categoriesCreateCtrl($rootScope, $location, $stateParams, Categories, $filter, SweetAlert) {
    var vm = this;

    vm.storePlayer = storePlayerFunction;
    vm.cancel = cancelFunction;

    $rootScope.loading = false;

    function storePlayerFunction() {
      SweetAlert.swal({
        'title': 'Confirm creation?',
        'text': 'A new Categories will be created.',
        'type': 'warning',
        'showCancelButton': true,
        'confirmButtonColor': '#5CB85C',
        'confirmButtonText': 'Confirm',
        'closeOnConfirm': true
      }, function(isConfirm) {
        if (isConfirm) {
          $rootScope.loading = true;
          Categories.store({
            label: vm.label,
            url: vm.url,
            isActive: vm.isActive
          }).then(function() {
            $rootScope.loading = false;
            $location.path('/course');
            SweetAlert.swal('Success', 'Categories created', 'success');
          }, function(err) {
            $rootScope.loading = false;
            SweetAlert.swal('Error', JSON.stringify(err), 'error');
          });
        } else {
          SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
        }
      });
    }

    function cancelFunction() {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This view will be closed.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      }, function(isConfirm) {
        if (isConfirm) {
          $location.path('/course');
        }
      });
    }
  }

})();
