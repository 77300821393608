(function () {
    'use strict';

    angular.module('backoffice.base', [])
        .config(configFunctionApp);

    function configFunctionApp($stateProvider) {

        var access = routingConfig.accessLevels;

        // Public routes
        $stateProvider
            .state('public', {
                abstract: true,
                template: '<ui-view/>',
                data: {
                    access: access.public
                }
            })
            .state('public.404', {
                url: '/404/',
                templateUrl: 'base/views/404.html'
            });

        // Anonymous routes
        $stateProvider
            .state('anon', {
                abstract: true,
                template: '<ui-view/>',
                data: {
                    access: access.anon
                }
            })
            .state('anon.login', {
                url: '/login/',
                templateUrl: 'base/views/login.html',
                controller: 'LoginCtrl',
                controllerAs: 'vm'
            });
        // Regular user routes
        $stateProvider
            .state('user', {
                abstract: true,
                template: '<ui-view/>',
                data: {
                    access: access.user
                }
            })
            .state('user.home', {
                url: '/',
                templateUrl: 'base/views/home.html',
                controller: 'HomeCtrl',
                controllerAs: 'vm'
            })
            .state('user.activities', {
                url: '/activities',
                templateUrl: 'base/views/activities.html',
                controller: 'ActvitiesCtrl',
                controllerAs: 'vm',
                resolve: {
                    objDataResolve: getAllData
                }
            });


        // Admin routes
        $stateProvider
            .state('admin', {
                abstract: true,
                template: '<ui-view/>',
                data: {
                    access: access.admin
                }
            })
            .state('admin.register', {
                url: '/register/',
                templateUrl: 'base/views/register.html',
                controller: 'RegisterCtrl',
                controllerAs: 'vm'
            });
    }


    function getAllData($log, $rootScope, $stateParams, Tracks, SweetAlert) {

        var vm = this;

        $rootScope.loading = true;

        vm.itemsPerPage = Tracks.getNavItemsPerPage();

        var obj = {
            pagination: {
                start: 0,
                totalItemCount: 0,
                number: vm.itemsPerPage
            },
            group: "active",
            sort: {},
            search: {}
        }

        return Tracks.getPage(obj)
            .then(function (resp) {
                $rootScope.loading = false;
                return resp.data.data;
            }, function (error) {
                $log.info("error orders");
                $rootScope.loading = false;
                SweetAlert.swal('Errore', 'Error in donwload the data', 'error');
            });

    }
})();