(function() {
  'use strict';
  angular.module('backoffice.users')
    .controller('UsersDetailCtrl', usersDetailCtrl);

  function usersDetailCtrl($stateParams) {
    var vm = this;
    vm.info = {};
    vm.info.id = $stateParams.id;
  }

})();
