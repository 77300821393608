(function () {
    'use strict';

    angular.module('backoffice.categories', [])
        .config(configFunction);

    function configFunction($stateProvider) {
        $stateProvider
            .state('admin.categories', {
                url: '/categories',
                templateUrl: 'categories/views/categories.html',
                controller: 'CategoriesCtrl',
                controllerAs: 'vm',
                resolve: {
                    objDataResolve: getAllData
                }

            })
            .state('admin.categories-create', {
                url: '/categories/create',
                templateUrl: 'categories/views/categories_create.html',
                controller: 'CategoriesCreateCtrl',
                controllerAs: 'vm'
            })
            .state('admin.categories-edit', {
                url: '/categories/:id',
                templateUrl: 'categories/views/categories_edit.html',
                controller: 'CategoriesEditCtrl',
                controllerAs: 'vm'
            });
    }

    function getAllData($log, $rootScope, $stateParams, Categories, SweetAlert) {

        var vm = this;

        $rootScope.loading = true;

        vm.itemsPerPage = Categories.getNavItemsPerPage();
        var obj = {
            pagination: {
                start: 0,
                totalItemCount: 0,
                number: vm.itemsPerPage
            },
            group: "active",
            sort: {},
            search: {}
        }

        return Categories.getPage(obj)
            .then(function (resp) {
                $rootScope.loading = false;
                return resp.data.data;
            }, function (error) {
                $log.info("error orders");
                $rootScope.loading = false;
                SweetAlert.swal('Errore', 'Error in donwload the data', 'error');
            });

    }
})();