(function () {

    'use strict';

    angular.module('backoffice.categories')
        .controller('CategoriesCtrl', categoriesCtrl);

    function categoriesCtrl(objDataResolve, $rootScope, $location, Categories, $filter, SweetAlert, $state) {
        var vm = this;

        vm.itemsByPage = 10;
        vm.currentPage = 0;
        vm.total = objDataResolve.total;
        vm.numberOfPages = Math.ceil(vm.total / vm.itemsByPage);
        vm._doit = false;
        vm.tableStateSaved = {};
        vm.tableStateSaved.pagination = {};
        vm.tableStateSaved.pagination.start = 0;
        vm.tableStateSaved.pagination.totalItemCount = 0;
        vm.tableStateSaved.pagination.number = vm.itemsByPage;
        vm.tableStateSaved.sort = {};
        vm.tableStateSaved.search = {};
        vm.makeRequest = makeRequestFunction;
        vm.saveQuery = saveQueryFunction;
        vm.getPage = getPageFunction;
        vm.deleteCategories = deleteCategoriesFunction;
        vm.changeState = changeStateFunction;
        vm.storeCategories = storeCategoriesFunction;

        $rootScope.makePagination = makePaginationFunction;
        $rootScope.loading = true;
        $rootScope.page_title = 'Categories';

        getPageFunction();

        function makeRequestFunction() {
            vm._doit = true;
        }

        function saveQueryFunction(tableState) {
            vm.tableStateSaved = tableState;
            if (vm._doit) {
                vm.getPage();
                vm._doit = false;
            }
        }

        function getPageFunction(currentPage) {
            $rootScope.loading = true;
            vm.currentPage = currentPage ? currentPage : 0;
            vm.tableStateSaved.pagination = {
                   start: currentPage *vm.itemsByPage,
                number: vm.itemsByPage
            }
            Categories.getPage(vm.tableStateSaved).then(function (resp) {
                $rootScope.loading = false;
                vm.info = resp.data.data.result;
                vm.total = resp.data.data.total;
                var toCeil = resp.data.data.total / vm.itemsByPage;
                vm.tableStateSaved.pagination.numberOfPages = Math.ceil(toCeil);
                vm.numberOfPages = Math.ceil(toCeil);
                console.log(vm.numberOfPages);
            }, function () {
                $rootScope.loading = true;
                $rootScope.error = 'Failed to fetch push';
            });
        }

        function makePaginationFunction(page) {
            $rootScope.currentPage = page;
            page--;
            vm.tableStateSaved.pagination.start = (page * vm.itemsByPage);
            vm.getPage();
        }

        function deleteCategoriesFunction(_id) {
            var data = {
                _id: _id
            };

            SweetAlert.swal({
                title: 'Are you sure?',
                text: 'The Category will be deleted.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Confirm',
                closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    Categories.delete(data).then(function () {
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        SweetAlert.swal('Success', 'Category deleted', 'success');
                    }, function (err) {
                        SweetAlert.swal('Error', err.message, 'error');
                    });
                } else {
                    SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                }
            });
        }

        function changeStateFunction(_id) {
            var data = {
                _id: _id
            };

            SweetAlert.swal({
                title: 'Are you sure?',
                text: 'The status will be changed.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Confirm',
                closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    Categories.changeStatus(data).then(function () {
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        SweetAlert.swal('Success', 'Status changed', 'success');
                    }, function (err) {
                        SweetAlert.swal('Error', err.message, 'error');
                    });
                } else {
                    SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                }
            });

        }


        function storeCategoriesFunction() {
            SweetAlert.swal({
                'title': 'Confirm creation?',
                'text': 'A new Categories will be created.',
                'type': 'warning',
                'showCancelButton': true,
                'confirmButtonColor': '#5CB85C',
                'confirmButtonText': 'Confirm',
                'closeOnConfirm': true
            }, function (isConfirm) {
                if (isConfirm) {
                    $rootScope.loading = true;
                    Categories.store({
                        name: vm.name,
                        description: vm.description,
                        image: vm.image
                    }).then(function () {
                        $rootScope.loading = false;
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        setTimeout(function () {
                            SweetAlert.swal('Success', 'Categories created', 'success');
                        }, 400);
                    }, function (err) {
                        $rootScope.loading = false;
                        setTimeout(function () {
                            SweetAlert.swal('Error', JSON.stringify(err), 'error');
                        }, 400);

                    });
                } else {
                    setTimeout(function () {
                        SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                    }, 400);
                }
            });
        }
    }
})();