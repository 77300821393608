(function() {
  'use strict';

  angular.module('backoffice.users', [])
    .config(configFunctionUsers);

  function configFunctionUsers($stateProvider) {
    $stateProvider
      .state('user.users', {
        url: '/users',
        templateUrl: 'users/views/users.html',
        controller: 'UsersCtrl',
        controllerAs: 'vm',
                resolve: {
                    objDataResolve: getAllData
                }
      }).state('user.users-updatePassword', {
        url: '/users/updatePassword',
        templateUrl: 'users/views/users_update_password.html',
        controller: 'UsersUpdatePasswordCtrl',
        controllerAs: 'vm'
      }).state('user.users-detail', {
        url: '/users/detail/:id',
        templateUrl: 'users/views/user_detail.html',
        controller: 'UsersDetailCtrl',
        controllerAs: 'vm',
                resolve: {
                    objDataResolve: getAllDetailData
                }
      });
  }
  function getAllData($log, $rootScope, $stateParams, Users, SweetAlert) {

        var vm = this;

        $rootScope.loading = true;

        vm.itemsPerPage = Users.getNavItemsPerPage();

        var obj = {
            pagination: {
                start: 0,
                number: vm.itemsPerPage
            },
            sort: {},
            search: {}
        }

        return Users.getPage(obj)
            .then(function (resp) {
                $rootScope.loading = false;
                return resp.data.data;
            }, function (error) {
                $log.info("error orders");
                $rootScope.loading = false;
                SweetAlert.swal('Errore', 'Error in donwload the data', 'error');
            });

    }
 function getAllDetailData($log, $rootScope, $stateParams, Analitycs, SweetAlert) {

        var vm = this;
        $rootScope.loading = true;
        var myid = $stateParams.id;
        vm.itemsPerPage = Analitycs.getNavItemsPerPage();
        var obj = {
            pagination: {
                start: 0,
                totalItemCount: 0,
                number: vm.itemsPerPage
            },
            sort: {},
            search: {}
        }
        return Analitycs.getForUser(obj,myid)
            .then(function (resp) {
                $rootScope.loading = false;
                console.log(1);
                return resp.data.data;
            }, function (error) {
                $log.info("error orders");
                $rootScope.loading = false;
                SweetAlert.swal('Errore', 'Error in donwload the data', 'error');
            });

    }
})();
