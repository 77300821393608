(function () {
    'use strict';
    angular
        .module('backoffice.base')
        .controller('HomeCtrl', homeCtrl);

    function homeCtrl($rootScope, Users, Auth, Tracks, SweetAlert, Course) {
        var vm = this;

        vm.loading_users = true;

        getInitial();
        $rootScope.page_title = 'Dashboard'

        function getInitial() {
            Users.getAll().then(function (resp) {
                vm.loading_users = false;
                vm.users_count = resp.data.data;
            }, function () {
                SweetAlert.swal('Error', 'Failed to fetch users.', 'error');
            });
            Users.getAllActive().then(function (resp) {
                vm.usersActive_count = resp.data.data;
            }, function () {
                SweetAlert.swal('Error', 'Failed to fetch users.', 'error');
            });
            Course.getAllCount().then(function (resp) {
                vm.course_count = resp.data.data;
            }, function () {
                SweetAlert.swal('Error', 'Failed to fetch courses.', 'error');
            });
            Users.registrations().then(function (resp) {
                vm.usersDate = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
        }
    }
})();