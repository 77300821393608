(function() {
  'use strict';

  angular.module('backoffice.courses', [])
    .config(configFunction);

  function configFunction($stateProvider) {
    $stateProvider
      .state('admin.courses', {
        url: '/courses',
        templateUrl: 'courses/views/courses.html',
        controller: 'CoursesCtrl',
        controllerAs: 'vm',
                resolve: {
                    objDataResolve: getAllData
                }

      })
      .state('admin.courses-create', {
        url: '/courses/create',
        templateUrl: 'courses/views/courses_create.html',
        controller: 'CoursesCreateCtrl',
        controllerAs: 'vm'
      });
  }
  function getAllData($log, $rootScope, $stateParams, Course, SweetAlert) {
        var vm = this;
        $rootScope.loading = true;
        vm.itemsPerPage = Course.getNavItemsPerPage();
        var obj = {
            pagination: {
                start: 0,
                totalItemCount: 0,
                number: vm.itemsPerPage
            },
            group: "active",
            sort: {},
            search: {}
        }

        return Course.getPage(obj)
            .then(function (resp) {
                $rootScope.loading = false;
                return resp.data.data;
            }, function (error) {
                $log.info("error orders");
                $rootScope.loading = false;
                SweetAlert.swal('Errore', 'Error in donwload the data', 'error');
            });

    }
})();