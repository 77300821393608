(function () {
    'use strict';

    angular.module('backoffice.analitycs', [])
        .config(configFunction);

    function configFunction($stateProvider) {
        $stateProvider
            .state('admin.analitycs', {
                url: '/courses/:id/analitycs',
                templateUrl: 'analitycs/views/analitycs.html',
                controller: 'AnalitycsCtrl',
                controllerAs: 'vm',
                resolve: {
                    objDataResolve: getAllCourseData,
                    objDataResolveUser: getAllCourseUserData
                }

            }).state('admin.analitycs_category', {
                url: '/categories/:id/analitycs',
                templateUrl: 'analitycs/views/categories_analitycs.html',
                controller: 'AnalitycsCategoriesCtrl',
                controllerAs: 'vm',
                resolve: {
                    objDataResolve: getAllData
                }
            });
    }
     function getAllData($log, $rootScope, $stateParams, Analitycs, SweetAlert) {

        var vm = this;
        vm.myid = $stateParams.id;
        $rootScope.loading = true;

        vm.itemsPerPage = Analitycs.getNavItemsPerPage();

        var obj = {
            pagination: {
                start: 0,
                totalItemCount: 0,
                number: vm.itemsPerPage
            },
            group: "active",
            sort: {},
            search: {}
        }

        return Analitycs.getForCategory(obj, vm.myid)
            .then(function (resp) {
                $rootScope.loading = false;
                return resp.data.data;
            }, function (error) {
                $log.info("error orders");
                $rootScope.loading = false;
                SweetAlert.swal('Errore', 'Error in donwload the data', 'error');
            });

    }
     function getAllCourseData($log, $rootScope, $stateParams, Analitycs, SweetAlert) {

        var vm = this;
        vm.myid = $stateParams.id;
        $rootScope.loading = true;

        vm.itemsPerPage = Analitycs.getNavItemsPerPage();

        var obj = {
            pagination: {
                start: 0,
                totalItemCount: 0,
                number: vm.itemsPerPage
            },
            group: "active",
            sort: {},
            search: {}
        }

        return Analitycs.getPage(obj, vm.myid)
            .then(function (resp) {
                $rootScope.loading = false;
                return resp.data.data;
            }, function (error) {
                $log.info("error orders");
                $rootScope.loading = false;
                SweetAlert.swal('Errore', 'Error in donwload the data', 'error');
            });

    }
     function getAllCourseUserData($log, $rootScope, $stateParams, Analitycs, SweetAlert) {

        var vm = this;
        vm.myid = $stateParams.id;
        $rootScope.loading = true;

        vm.itemsPerPage = Analitycs.getNavItemsPerPage();

        var obj = {
            pagination: {
                start: 0,
                totalItemCount: 0,
                number: vm.itemsPerPage
            },
            group: "active",
            sort: {},
            search: {}
        }

        return Analitycs.getUserPage(obj, vm.myid)
            .then(function (resp) {
                $rootScope.loading = false;
                return resp.data.data;
            }, function (error) {
                $log.info("error orders");
                $rootScope.loading = false;
                SweetAlert.swal('Errore', 'Error in donwload the data', 'error');
            });

    }
})();
