(function () {
    'use strict';
    angular.module('backoffice.users')
        .controller('UsersCtrl', usersCtrl);

    function usersCtrl(objDataResolve, $rootScope, $location, Users, SweetAlert, $state, envService) {
        var vm = this;
        console.log('ENV: ', envService.get() );
        vm.itemsByPage = 10;
        vm.currentPage = 0;
        vm.total = objDataResolve.total;
        vm.numberOfPages = Math.ceil(vm.total / vm.itemsByPage);
        vm._doit = false;
        vm.tableStateSaved = {};
        vm.tableStateSaved.pagination = {};
        vm.tableStateSaved.pagination.start = 0;
        vm.tableStateSaved.pagination.totalItemCount = 0;
        vm.tableStateSaved.pagination.number = vm.itemsByPage;
        vm.tableStateSaved.sort = {};
        vm.tableStateSaved.search = {};
        vm.banUser = banUser;
        vm.makeRequest = makeRequestFunction;
        vm.saveQuery = saveQueryFunction;
        vm.getPage = getPageFunction;
        vm.donwloadCsv = donwloadCsvFunction;

        $rootScope.makePagination = makePaginationFunction;
        $rootScope.page_title = 'Users';
        $rootScope.currentPage = 0;

        getDistinct();
        getPageFunction();
        getInitialContent();

        function getDistinct() {
            Users.getDistinct().then(function (resp) {
                vm.distinct = resp.data.data;
            }, function () {
                $rootScope.error = 'Failed to fetch users';
            });
        }

        function donwloadCsvFunction() {
            $rootScope.loading = true;
            Users.getCsv().then(function (resp) {
                $rootScope.loading = false;
                SweetAlert.swal({
                    title: "Your file is ready to download",
                    text: "<a href='" + resp.data.data.url + "' target='_blank'>Click here to download<a>",
                    html: true
                });
            }, function () {
                $rootScope.loading = false;
                $rootScope.error = 'Failed to fetch push';
            });
        }

        function makePaginationFunction(page) {
            $rootScope.currentPage = page;
            page--;
            vm.tableStateSaved.pagination.start = (page * vm.itemsByPage);
            vm.getPage();
        }

        function makeRequestFunction() {
            vm._doit = true;
        }

        function saveQueryFunction(tableState) {
            vm.tableStateSaved = tableState;
            if (vm._doit) {
                vm.getPage();
                vm._doit = false;
            }
        }

        function getPageFunction(currentPage) {
            $rootScope.loading = true;
            vm.currentPage = currentPage ? currentPage : 0;
            vm.tableStateSaved.pagination = {
                start: currentPage * vm.itemsByPage,
                number: vm.itemsByPage
            }
            Users.getPage(vm.tableStateSaved).then(function (resp) {
                $rootScope.loading = false;
                vm.info = resp.data.data.result;
                vm.total = resp.data.data.total;
                vm.tableStateSaved.pagination.numberOfPages = Math.ceil(resp.data.data.total / vm.itemsByPage);
            }, function () {
                $rootScope.loading = true;
                $rootScope.error = 'Failed to fetch users';
            });
        }

        function getInitialContent() {
            Users.registrations().then(function (resp) {
                vm.usersDate = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
            Users.registrationsH().then(function (resp) {
                vm.usersDateH = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
            Users.registrationsW().then(function (resp) {
                vm.usersDateW = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
            Users.getFrom().then(function (resp) {
                vm.usersRegitration = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
            Users.getEmailActive().then(function (resp) {
                vm.usersEmail = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
        }

        function banUser(user) {
            if (user.banned) {
                var _message = "Confirm to reactive the user?"
                var _text = "The user will be able to login into the app"
            } else {
                var _message = "Confirm to ban the user?"
                var _text = "The user will be unable to login into the app"
            }
            SweetAlert.swal({
                title: _message,
                text: _text,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5CB85C',
                confirmButtonText: 'Confirm',
                closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    $rootScope.loading = true;
                    Users.activeUser(user._id, user.banned).then(function (resp) {
                        $rootScope.loading = false;
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        SweetAlert.swal('Success', 'User status changed.', 'success');
                    }, function (err) {
                        $rootScope.loading = false;
                        SweetAlert.swal('Error', err.message, 'error');
                    });
                } else {
                    SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                }
            });
        }
    }

})();