(function() {
  'use strict';
  angular.module('backoffice.services')
    .factory('Auth', authService);

  function authService($http, AuthToken) {
    var accessLevels = routingConfig.accessLevels,
      userRoles = routingConfig.userRoles;
    var currentUser = {
      email: AuthToken.getUser() ? AuthToken.getUser().email : '',
      role: AuthToken.getUser() ? AuthToken.getUser().role : userRoles.public
    };

    var _service = {
      authorize: authorize,
      isLoggedIn: isLoggedIn,
      register: register,
      info: info,
      login: login,
      logout: logout,
      accessLevels: accessLevels,
      userRoles: userRoles,
      user: currentUser
    };

    return _service;


    function changeUser(user) {
      angular.extend(currentUser, user);
    }

    function authorize(accessLevel, role) {
      if (role === undefined)
        role = currentUser.role;

      return accessLevel.bitMask & role.bitMask;
    }

    function isLoggedIn(user) {
      if (user === undefined)
        user = currentUser;
      var _orValue = user.role.title == userRoles.admin.title;
      return user.role.title == userRoles.user.title || _orValue;
    }

    function register(user) {
      return $http.post('/backoffice/v1/register', user);
    }

    function info() {
      return $http.get('/backoffice/v1/info');
    }

    function login(user, success, error) {
      $http
        .post('/backoffice/v1/login', user)
        .success(function(res) {
          AuthToken.setToken(res.data.accessToken);
          AuthToken.setUser(res.data.user);
          changeUser(res.data.user);
          success();
        })
        .error(error);
    }

    function logout(success) {
      AuthToken.setToken();
      AuthToken.setUser();
      changeUser({
        username: '',
        role: userRoles.public
      });
      success();
    }

  }

})();
