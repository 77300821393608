(function() {
  'use strict';
  angular.module('backoffice.base')
    .filter('showUserType', showUserType);

  function showUserType() {
    return function(input, uppercase) {
      if (input) return 'Admin'
      else return 'User'
    };
  }
})();
