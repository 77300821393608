(function() {
  'use strict';

  angular.module('backoffice.pushes')
    .controller('PushesDetailCtrl', pushesDetailCtrl);

  function pushesDetailCtrl($rootScope, $scope, $stateParams, Pushes, $filter, SweetAlert) {
    $scope.sort = function(keyname) {
      $scope.sortKey = keyname;
      $scope.reverse = !$scope.reverse;
    }

    $scope.info = {};
    $scope.id = $stateParams.id;

    $rootScope.loading = true;

    Pushes.getOne($stateParams.id).then(function(resp) {
      $scope.notification = resp.data.data;
      $scope.info = resp.data.data.targets;
      $rootScope.loading = false;
    }, function(err) {
      $rootScope.loading = false;
      SweetAlert.swal('Error', 'Failed to fetch push', 'error');
    });
  }

})();
