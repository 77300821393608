(function() {
  'use strict';

  angular.module('backoffice.base')
    .directive('accessLevel', accessLevel);

  function accessLevel(Auth) {
    return {
      restrict: 'A',
      link: linkFunctionAccessLevel
    };

    function linkFunctionAccessLevel($scope, element, attrs) {
      var prevDisp = element.css('display'),
        userRole, accessLevel;

      $scope.user = Auth.user;
      $scope.$watch('user', function(user) {
        if (user.role)
          userRole = user.role;
        updateCSS();
      }, true);

      attrs.$observe('accessLevel', function(al) {
        if (al) accessLevel = $scope.$eval(al);
        updateCSS();
      });

      function updateCSS() {
        if (userRole && accessLevel) {
          if (!Auth.authorize(accessLevel, userRole))
            element.css('display', 'none');
          else
            element.css('display', prevDisp);
        }
      }
    }
  }



})();
