(function () {
    'use strict';
    angular.module('backoffice.installations')
        .controller('InstallationsCtrl', installationsCtrl);

    function installationsCtrl(objDataResolve,$rootScope, $location, Installations) {
        var vm = this;

        vm.itemsByPage = 10;
        vm.currentPage = 0;
         vm.total = objDataResolve.total;
        vm.numberOfPages = Math.ceil(vm.total / vm.itemsByPage);
        vm._doit = false;
        vm.tableStateSaved = {};
        vm.tableStateSaved.pagination = {};
        vm.tableStateSaved.pagination.start = 0;
        vm.tableStateSaved.pagination.totalItemCount = 0;
        vm.tableStateSaved.pagination.number = vm.itemsByPage;
        vm.tableStateSaved.sort = {};
        vm.tableStateSaved.search = {};
        vm.makeRequest = makeRequestFunction;
        vm.saveQuery = saveQueryFunction;
        vm.getPage = getPageFunction;

        $rootScope.makePagination = makePaginationFunction;
        $rootScope.page_title = 'Installations';
        getDistinct();
        getPageFunction();
        getInitialContent();

        function getDistinct() {
            Installations.getDistinct().then(function (resp) {
                vm.distinct = resp.data.data;
            }, function () {
                $rootScope.error = 'Failed to fetch installations';
            });
        }

        function makePaginationFunction(page) {
            $rootScope.currentPage = page;
            page--;
            vm.tableStateSaved.pagination.start = (page * vm.itemsByPage);
            vm.getPage();
        }

        function makeRequestFunction() {
            vm._doit = true;
        }

        function saveQueryFunction(tableState) {
            vm.tableStateSaved = tableState;
            if (vm._doit) {
                vm.getPage();
                vm._doit = false;
            }
        }

        function getPageFunction(currentPage) {
            $rootScope.loading = true;
              vm.currentPage = currentPage ? currentPage : 0;
            vm.tableStateSaved.pagination = {
                  start: currentPage *vm.itemsByPage,
                number: vm.itemsByPage
            }
            Installations.getPage(vm.tableStateSaved).then(function (resp) {
                $rootScope.loading = false;
                vm.info = resp.data.data.result;
                vm.total = resp.data.data.total;
                vm.tableStateSaved.pagination.numberOfPages = Math.ceil(resp.data.data.total / vm.itemsByPage);
            }, function () {
                $rootScope.loading = true;
                $rootScope.error = 'Failed to fetch installations';
            });
        }

        function getInitialContent() {
            Installations.registrations().then(function (resp) {
                vm.installationsDate = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
            Installations.registrationsH().then(function (resp) {
                vm.installationsDateH = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
            Installations.registrationsW().then(function (resp) {
                vm.installationsDateW = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
            Installations.getDevice().then(function (resp) {
                vm.installationsDevice = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
            Installations.getVersionAndroid().then(function (resp) {
                vm.installationsVersionAndroid = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
            Installations.getVersionIos().then(function (resp) {
                vm.installationsVersionIos = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
            Installations.getPushActive().then(function (resp) {
                vm.installationsPush = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
        }

    }

})();
