(function() {
  'use strict';
  angular
    .module('backoffice.base')
    .controller('NavCtrl', navCtrl);

  function navCtrl($rootScope, $location, Auth, SweetAlert) {
    var vm = this;

    vm.logout = logoutFunction;
    vm.user = Auth.user;
    vm.userRoles = Auth.userRoles;
    vm.accessLevels = Auth.accessLevels;

    AuthInfo();

    function AuthInfo() {
      Auth.info().then(function(data) {
        var _data = data.data;
        vm.name = _data.name;
      }, function() {
        SweetAlert.swal('Error', 'Failed to get info', 'error');
      });
    }

    function logoutFunction() {
      Auth.logout(function() {
        $location.path('/login');
      });
    }

  }
})();
