(function () {

    'use strict';

    angular.module('backoffice.categories')
        .controller('CategoriesEditCtrl', categoriesCreateCtrl);

    function categoriesCreateCtrl($rootScope, $location, $stateParams, Categories, $filter, SweetAlert) {
        var vm = this;

        vm.storePlayer = storePlayerFunction;
        vm.cancel = cancelFunction;
        vm.findInfo = findInfoFunction;
        $rootScope.loading = false;
        $rootScope.page_title = 'Edit category';
        vm.findInfo();
        function findInfoFunction() {
            vm.id = $stateParams.id;
            Categories.getOne(vm.id).then(function (data) {
                vm.info = data.data.data;
            }, function (err) {
                $rootScope.loading = false;
                SweetAlert.swal('Error', JSON.stringify(err), 'error');
            });
        }
        function storePlayerFunction() {
            SweetAlert.swal({
                'title': 'Confirm creation?',
                'text': 'The Category will be edited.',
                'type': 'warning',
                'showCancelButton': true,
                'confirmButtonColor': '#5CB85C',
                'confirmButtonText': 'Confirm',
                'closeOnConfirm': true
            }, function (isConfirm) {
                if (isConfirm) {
                    $rootScope.loading = true;
                    Categories.edit(vm.info).then(function () {
                        $rootScope.loading = false;
                        $location.path('/categories');
                        SweetAlert.swal('Success', 'Category Edited', 'success');
                    }, function (err) {
                        $rootScope.loading = false;
                        SweetAlert.swal('Error', JSON.stringify(err), 'error');
                    });
                } else {
                    SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                }
            });
        }

        function cancelFunction() {
            SweetAlert.swal({
                title: 'Are you sure?',
                text: 'This view will be closed.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Confirm',
                closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    $location.path('/categories');
                }
            });
        }
    }

})();
