(function () {
    'use strict';
    angular.module('backoffice.users')
        .controller('UsersDetailCtrl', usersDetailCtrl);

    function usersDetailCtrl(objDataResolve,$stateParams, $rootScope, Users, Analitycs, $state) {
        var vm = this;

        vm.itemsByPage = 10;
        vm.currentPage = 0;
             vm.total = objDataResolve.total;
        vm.numberOfPages = Math.ceil(vm.total / vm.itemsByPage);
        vm._doit = false;
        vm.tableStateSaved = {};
        vm.tableStateSaved.pagination = {};
        vm.tableStateSaved.pagination.start = 0;
        vm.tableStateSaved.pagination.totalItemCount = 0;
        vm.tableStateSaved.pagination.number = vm.itemsByPage;
        vm.tableStateSaved.sort = {};
        vm.tableStateSaved.search = {};
        vm.makeRequest = makeRequestFunction;
        vm.saveQuery = saveQueryFunction;
        vm.getPage = getPageFunction;
        vm.user = {};
        vm.user._id = $stateParams.id;
        vm.getOne = getOneFunction;

        $rootScope.makePagination = makePaginationFunction;
        $rootScope.page_title = 'User Detail';
        $rootScope.currentPage = 0;

        getOneFunction();
        getPageFunction();

        function makeRequestFunction() {
            vm._doit = true;
        }


        function saveQueryFunction(tableState) {
            vm.tableStateSaved = tableState;
            if (vm._doit) {
                vm.getUserPage();
                vm._doit = false;
            }
        }


        function getPageFunction(currentPage) {
            $rootScope.loading = true;
             vm.currentPage = currentPage ? currentPage : 0;
            vm.tableStateSaved.pagination = {
                   start: currentPage *vm.itemsByPage,
                number: vm.itemsByPage
            }
            Analitycs.getForUser(vm.tableStateSaved, vm.user._id).then(function (resp) {
                $rootScope.loading = false;
                vm.info = resp.data.data.result;
                vm.total = resp.data.data.total;
                var toCeil = resp.data.data.total / vm.itemsByPage;
                vm.tableStateSaved.pagination.numberOfPages = Math.ceil(toCeil);
                $rootScope.numPages = Math.ceil(toCeil) - 1;
            }, function () {
                $rootScope.loading = true;
                $rootScope.error = 'Failed to fetch push';
            });
        }

        function makePaginationFunction(page) {
            vm.currentPage = page;
            $rootScope.currentPage = page;
            page--;
            vm.tableStateSaved.pagination.start = (page * vm.itemsByPage);
            vm.getPage();
        }

        function getOneFunction() {
            $rootScope.loading = true;
            Users.getOne(vm.user._id).then(function (resp) {
                $rootScope.loading = false;
                vm.user = resp.data.data;
            }, function () {
                $rootScope.loading = false;
                $rootScope.error = 'Failed to fetch users detail';
            });
        }
    }


})();
