(function () {

    'use strict';

    angular.module('backoffice.courses')
        .controller('CoursesCtrl', coursesCtrl);

    function coursesCtrl(objDataResolve,$rootScope, $location, Course, Categories, $filter, SweetAlert, $state) {
        var vm = this;
        vm.itemsByPage = 10;
        vm.currentPage = 0;
        vm.total = objDataResolve.total;
        vm.numberOfPages = Math.ceil(vm.total / vm.itemsByPage);
        vm._doit = false;
        vm.tableStateSaved = {};
        vm.tableStateSaved.pagination = {};
        vm.tableStateSaved.pagination.start = 0;
        vm.tableStateSaved.pagination.totalItemCount = 0;
        vm.tableStateSaved.pagination.number = vm.itemsByPage;
        vm.tableStateSaved.sort = {};
        vm.tableStateSaved.search = {};
        vm.makeRequest = makeRequestFunction;
        vm.saveQuery = saveQueryFunction;
        vm.getPage = getPageFunction;
        vm.deleteCourse = deleteCourseFunction;
        vm.changeState = changeStateFunction;
        vm.storeCourse = storeCourseFunction;
        vm.selectCourse = selectCourseFunction;
        vm.editCourse = editCourseFunction;

        $rootScope.makePagination = makePaginationFunction;
        $rootScope.loading = true;
        $rootScope.page_title = 'Courses';

        getDistinct();
        getCategories();
        getPageFunction();

        function getDistinct() {
            Course.getDistinct().then(function (resp) {
                vm.distinct = resp.data.data;
            }, function () {
                $rootScope.error = 'Failed to fetch users';
            });
        }

        function getCategories() {
            Categories.getAll().then(function (data) {
                vm.categories = data.data.data;
            }).catch(function (err) {
                vm.categories = [];
            })
        }

        function makeRequestFunction() {
            vm._doit = true;
        }

        function saveQueryFunction(tableState) {
            vm.tableStateSaved = tableState;
            if (vm._doit) {
                vm.getPage();
                vm._doit = false;
            }
        }

        function getPageFunction(currentPage) {
            $rootScope.loading = true;
              vm.currentPage = currentPage ? currentPage : 0;
            vm.tableStateSaved.pagination = {
                   start: currentPage *vm.itemsByPage,
                number: vm.itemsByPage
            }
            Course.getPage(vm.tableStateSaved).then(function (resp) {
                $rootScope.loading = false;
                vm.info = resp.data.data.result;
                vm.total = resp.data.data.total;
                var toCeil = resp.data.data.total / vm.itemsByPage;
                vm.tableStateSaved.pagination.numberOfPages = Math.ceil(toCeil);
            }, function () {
                $rootScope.loading = true;
                $rootScope.error = 'Failed to fetch push';
            });
        }

        function makePaginationFunction(page) {
            $rootScope.currentPage = page;
            page--;
            vm.tableStateSaved.pagination.start = (page * vm.itemsByPage);
            vm.getPage();
        }

        function selectCourseFunction(row) {
            vm.selected = row;
        }

        function deleteCourseFunction(_id) {
            var data = {
                _id: _id
            };

            SweetAlert.swal({
                title: 'Are you sure?',
                text: 'The Course will be deleted.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Confirm',
                closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    Course.delete(data).then(function () {
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        SweetAlert.swal('Success', 'Course deleted', 'success');
                    }, function (err) {
                        SweetAlert.swal('Error', err.message, 'error');
                    });
                } else {
                    SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                }
            });
        }

        function changeStateFunction(_id) {
            var data = {
                _id: _id
            };

            SweetAlert.swal({
                title: 'Are you sure?',
                text: 'The status will be changed.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Confirm',
                closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    Course.changeStatus(data).then(function () {
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        SweetAlert.swal('Success', 'Status changed', 'success');
                    }, function (err) {
                        SweetAlert.swal('Error', err.message, 'error');
                    });
                } else {
                    SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                }
            });

        }


        function storeCourseFunction() {
            SweetAlert.swal({
                'title': 'Confirm creation?',
                'text': 'A new Course will be created.',
                'type': 'warning',
                'showCancelButton': true,
                'confirmButtonColor': '#5CB85C',
                'confirmButtonText': 'Confirm',
                'closeOnConfirm': true
            }, function (isConfirm) {
                if (isConfirm) {
                    $rootScope.loading = true;
                    $('#newCourseModal').modal('hide')
                    Course.store({
                        name: vm.name,
                        description: vm.description,
                        questionsNumber: vm.questionsNumber,
                        image: vm.image,
                        pdf:vm.pdf,
                        category: vm.category,
                        time: vm.time,
                        minTest : vm.minTest
                    }).then(function () {
                        $rootScope.loading = false;
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        setTimeout(function () {
                            SweetAlert.swal('Success', 'Course created', 'success');
                        }, 400);
                    }, function (err) {
                        $rootScope.loading = false;
                        setTimeout(function () {
                            SweetAlert.swal('Error', JSON.stringify(err), 'error');
                        }, 400);

                    });
                } else {
                    setTimeout(function () {
                        SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                    }, 400);
                }
            });
        }

        function editCourseFunction() {
            SweetAlert.swal({
                'title': 'Confirm editing?',
                'text': 'A Course will be edited.',
                'type': 'warning',
                'showCancelButton': true,
                'confirmButtonColor': '#5CB85C',
                'confirmButtonText': 'Confirm',
                'closeOnConfirm': true
            }, function (isConfirm) {
                if (isConfirm) {
                    $rootScope.loading = true;
                    $('#editCourseModal').modal('hide');
                    Course.edit(vm.selected).then(function () {
                        $rootScope.loading = false;
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        setTimeout(function () {
                            SweetAlert.swal('Success', 'Course edited', 'success');
                        }, 400);
                    }, function (err) {
                        $rootScope.loading = false;
                        setTimeout(function () {
                            SweetAlert.swal('Error', JSON.stringify(err), 'error');
                        }, 400);

                    });
                } else {
                    setTimeout(function () {
                        SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                    }, 400);
                }
            });
        }
    }
})();
