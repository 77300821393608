(function () {

    'use strict';

    angular.module('backoffice.analitycs')
        .controller('AnalitycsCtrl', testsCtrl);

    function testsCtrl(objDataResolve,objDataResolveUser,$rootScope, $location, Analitycs, $stateParams, $filter, SweetAlert, $state, Upload, envService, $timeout, $window) {
        var vm = this;

        vm.itemsByPage = 10;
        vm.currentPage = 0;
        vm.total = objDataResolve.total;
        vm.numberOfPages = Math.ceil(vm.total / vm.itemsByPage);

        vm.currentPage2 = 0;
        vm.totalUser = objDataResolveUser.total;
        vm.numberOfPagesUser = Math.ceil(vm.totalUser / vm.itemsByPage);
        vm._doit = false;
        vm._doit2 = false;
        vm.myid = $stateParams.id;

        vm.tableStateSaved = {};
        vm.tableStateSaved.pagination = {};
        vm.tableStateSaved.pagination.start = 0;
        vm.tableStateSaved.pagination.totalItemCount = 0;
        vm.tableStateSaved.pagination.number = vm.itemsByPage;
        vm.tableStateSaved.sort = {};
        vm.tableStateSaved.search = {};

        vm.tableStateSavedUser = {};
        vm.tableStateSavedUser.pagination = {};
        vm.tableStateSavedUser.pagination.start = 0;
        vm.tableStateSavedUser.pagination.totalItemCount = 0;
        vm.tableStateSavedUser.pagination.number = vm.itemsByPage;
        vm.tableStateSavedUser.sort = {};
        vm.tableStateSavedUser.search = {};

        vm.makeRequest = makeRequestFunction;
        vm.makeRequestUser = makeRequestUserFunction;
        vm.saveQuery = saveQueryFunction;
        vm.saveQueryUser = saveQueryUserFunction;
        vm.getPage = getPageFunction;
        vm.getUserPage = getUserPageFunction;
        vm.donwloadSessions = donwloadSessionsFunction;
        vm.donwloadUser = donwloadUserFunction;

        $rootScope.makePagination = makePaginationFunction;
        $rootScope.makePaginationUser = makePaginationFunctionUser;

        $rootScope.loading = true;
        $rootScope.page_title = 'Course Analitycs';
        getPageFunction();
        getUserPageFunction(0);
        getInitialContent();

        function deleteAnotherAnswerFunction(index) {
            vm.answers.splice(index, 1);
        }

        function addAnotherAnswerFunction() {
            vm.answers.push({
                text: '',
                right: false
            })
        }

        function makeRequestFunction() {
            vm._doit = true;
        }

        function makeRequestUserFunction() {
            vm._doit = true;
        }

        function saveQueryUserFunction(tableState) {
            vm.tableStateSavedUser = tableState;
            if (vm._doit2) {
                vm.getUserPage();
                vm._doit2 = false;
            }
        }

        function saveQueryFunction(tableState) {
            vm.tableStateSaved = tableState;
            if (vm._doit) {
                vm.getPage();
                vm._doit = false;
            }
        }

        function getPageFunction(currentPage) {
            $rootScope.loading = true;
             vm.currentPage = currentPage ? currentPage : 0;
            vm.tableStateSaved.pagination = {
                start: currentPage*vm.itemsByPage,
                number: vm.itemsByPage
            }
            Analitycs.getPage(vm.tableStateSaved, vm.myid).then(function (resp) {
                $rootScope.loading = false;
                vm.info = resp.data.data.result;
                vm.total = resp.data.data.total;
                var toCeil = resp.data.data.total / vm.itemsByPage;
                vm.tableStateSaved.pagination.numberOfPages = Math.ceil(toCeil);
                vm.numberOfPages = Math.ceil(toCeil);
            }, function () {
                $rootScope.loading = true;
                $rootScope.error = 'Failed to fetch push';
            });
        }

        function donwloadSessionsFunction() {
            $rootScope.loading = true;
            Analitycs.getSessionCsv(vm.myid).then(function (resp) {
                $rootScope.loading = false;
                SweetAlert.swal({
                    title: "Your file is ready to download",
                    text: "<a href='"+resp.data.data.url+"' target='_blank'>Click here to download<a>",
                    html: true
                });
            }, function () {
                $rootScope.loading = false;
                $rootScope.error = 'Failed to fetch push';
            });
        }
        
        function donwloadUserFunction() {
            $rootScope.loading = true;
            Analitycs.getUserCsv(vm.myid).then(function (resp) {
                $rootScope.loading = false;
                SweetAlert.swal({
                    title: "Your file is ready to download",
                    text: "<a href='"+resp.data.data.url+"' target='_blank'>Click here to download<a>",
                    html: true
                });
            }, function () {
                $rootScope.loading = false;
                $rootScope.error = 'Failed to fetch push';
            });
        }

        
        function getUserPageFunction(currentPage) {
            $rootScope.loading = true;
            vm.currentPage2 = currentPage ? currentPage : 0;
            vm.tableStateSavedUser.pagination = {
                start: currentPage*vm.itemsByPage,
                number: vm.itemsByPage
            }
            console.log(vm.tableStateSaved.pagination);
            Analitycs.getUserPage(vm.tableStateSavedUser, vm.myid).then(function (resp) {
                $rootScope.loading = false;
                vm.users = resp.data.data.result;
                vm.totalUser = resp.data.data.total;
                var toCeil = resp.data.data.total / vm.itemsByPage;
                vm.tableStateSavedUser.pagination.numberOfPages = Math.ceil(toCeil);
                vm.numberOfPagesUser = Math.ceil(toCeil);
            }, function () {
                $rootScope.loading = true;
                $rootScope.error = 'Failed to fetch push';
            });
        }

        function makePaginationFunction(page) {
            $rootScope.currentPage = page;
            page--;
            vm.tableStateSaved.pagination.start = (page * vm.itemsByPage);
            vm.getPage();
        }

        function makePaginationFunctionUser(page) {
            $rootScope.currentPage2 = page;
            page--;
            vm.tableStateSavedUser.pagination.start = (page * vm.itemsByPage);
            vm.getUserPage();
        }

        function getInitialContent() {
            Analitycs.registrations().then(function (resp) {
                vm.usersDate = resp.data.data;
            }, function (err) {
                $rootScope.error = err;
            });
        }
    }
})();