(function() {
  'use strict';
  angular.module('backoffice.users')
    .controller('UsersUpdatePasswordCtrl', usersUpdatePasswordCtrl);

  function usersUpdatePasswordCtrl($rootScope, $location, $stateParams, Users, $filter, SweetAlert) {
    var vm = this;

    vm.updatePassword = updatePasswordFunction;
    vm.cancel = cancelFunction;
    $rootScope.page_title = 'Edit Password';
    function updatePasswordFunction() {
      var data = {
        oldPassword: vm.oldPassword,
        password: vm.password
      };
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'The password will be updated',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        closeOnConfirm: false
      }, function(isConfirm) {
        if (isConfirm) {
          Users.updatePassword(data).then(function() {
            SweetAlert.swal('Success', 'Password updated', 'success');
            $location.path('/');
          }, function(err) {
            SweetAlert.swal('Error', err.message, 'error');
          });
        } else {
          SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
        }

      });
    }

    function cancelFunction() {
      SweetAlert.swal({
        title: 'Are you sure?',
        text: 'This view will be closed.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      }, function(isConfirm) {
        if (isConfirm) {
          $location.path('/');
        }
      });
    }
  }
})();
