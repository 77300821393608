(function () {

    'use strict';

    angular.module('backoffice.tests')
        .controller('TestsCtrl', testsCtrl);

    function testsCtrl(objDataResolve, $rootScope, $location, Tests, $stateParams, $filter, SweetAlert, $state, Upload, envService, $timeout) {
        var vm = this;

        vm.itemsByPage = 10;
        vm.currentPage = 0;
        vm.total = objDataResolve.total;
        vm.numberOfPages = Math.ceil(vm.total / vm.itemsByPage);
        vm._doit = false;
        vm.myid = $stateParams.id;
        vm.tableStateSaved = {};
        vm.tableStateSaved.pagination = {};
        vm.tableStateSaved.pagination.start = 0;
        vm.tableStateSaved.pagination.totalItemCount = 0;
        vm.tableStateSaved.pagination.number = vm.itemsByPage;
        vm.tableStateSaved.sort = {};
        vm.tableStateSaved.search = {};
        vm.makeRequest = makeRequestFunction;
        vm.saveQuery = saveQueryFunction;
        vm.getPage = getPageFunction;
        vm.deleteTests = deleteTestsFunction;
        vm.changeState = changeStateFunction;
        vm.storeTests = storeTestsFunction;
        vm.addAnotherAnswer = addAnotherAnswerFunction;
        vm.deleteAnotherAnswer = deleteAnotherAnswerFunction;
        vm.type = 'single';
        vm.answers = [{
            text: '',
            right: false
    }, {
            text: '',
            right: false
    }]

        $rootScope.makePagination = makePaginationFunction;
        $rootScope.loading = true;
        $rootScope.page_title = 'Tests';
        getPageFunction();

        function deleteAnotherAnswerFunction(index) {
            vm.answers.splice(index, 1);
        }

        function addAnotherAnswerFunction() {
            vm.answers.push({
                text: '',
                right: false
            })
        }

        function makeRequestFunction() {
            vm._doit = true;
        }

        function saveQueryFunction(tableState) {
            vm.tableStateSaved = tableState;
            if (vm._doit) {
                vm.getPage();
                vm._doit = false;
            }
        }

        function getPageFunction(currentPage) {
            vm.currentPage = currentPage ? currentPage : 0;
            vm.tableStateSaved.pagination = {
                start: currentPage * vm.itemsByPage,
                number: vm.itemsByPage
            }
            $rootScope.loading = true;
            Tests.getPage(vm.tableStateSaved, vm.myid).then(function (resp) {
                $rootScope.loading = false;
                vm.info = resp.data.data.result;
                vm.total = resp.data.data.total;
                var toCeil = resp.data.data.total / vm.itemsByPage;
                vm.tableStateSaved.pagination.numberOfPages = Math.ceil(toCeil);
                vm.numberOfPages = Math.ceil(toCeil);
            }, function () {
                $rootScope.loading = true;
                $rootScope.error = 'Failed to fetch push';
            });
        }

        function makePaginationFunction(page) {
            $rootScope.currentPage = page;
            page--;
            vm.tableStateSaved.pagination.start = (page * vm.itemsByPage);
            vm.getPage();
        }

        function deleteTestsFunction(_id) {
            var data = {
                _id: _id
            };

            SweetAlert.swal({
                title: 'Are you sure?',
                text: 'The Test will be deleted.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Confirm',
                closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    Tests.delete(data).then(function () {
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        SweetAlert.swal('Success', 'Test deleted', 'success');
                    }, function (err) {
                        SweetAlert.swal('Error', err.message, 'error');
                    });
                } else {
                    SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                }
            });
        }

        function changeStateFunction(_id) {
            var data = {
                _id: _id
            };

            SweetAlert.swal({
                title: 'Are you sure?',
                text: 'The status will be changed.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Confirm',
                closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    Tests.changeStatus(data).then(function () {
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        SweetAlert.swal('Success', 'Status changed', 'success');

                    }, function (err) {
                        SweetAlert.swal('Error', err.message, 'error');
                    });
                } else {
                    SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                }
            });

        }

        function storeTestsFunction(file) {
            SweetAlert.swal({
                'title': 'Confirm creation?',
                'text': 'A new Test will be created.',
                'type': 'warning',
                'showCancelButton': true,
                'confirmButtonColor': '#5CB85C',
                'confirmButtonText': 'Confirm',
                'closeOnConfirm': true
            }, function (isConfirm) {
                if (isConfirm) {
                    $rootScope.loading = true;
                    var _canPass = true;
                    var _replyFounded = false;
                    var _obj = {
                        title: vm.title,
                        question: vm.question,
                        type: vm.type,
                        answers: vm.answers,
                        course: vm.myid
                    };
                    if (vm.type === 'single') {
                        for (var i in _obj.answers) {
                            _obj.answers[i].right = (parseInt(i) === parseInt(vm.right)) ? true : false;
                        }
                    } else {
                        var _app = [];
                        for (var i in _obj.answers) {
                            _obj.answers[i].right = _obj.answers[i].right ? true : false;
                        }
                    }

                    if (vm.title === undefined || vm.title === '') {
                        _canPass = false;
                        vm.errorMessage = 'Please insert title'
                    }
                    if (vm.question === undefined || vm.question === '') {
                        _canPass = false;
                        vm.errorMessage = 'Please insert the question'
                    }
                    for (var i in  _obj.answers) {
                        if ( _obj.answers[i].text === '' ||  _obj.answers[i].text === undefined) {
                            _canPass = false;
                            vm.errorMessage = 'Please insert all answers text'
                        }
                        if ( _obj.answers[i].right === true) {
                            _replyFounded = true;
                        }
                    }
                    vm.notCanPass = !_canPass;
                    if (!_replyFounded) {
                        vm.notCanPass = true;
                        vm.errorMessage = 'Please select the valid answers'
                    }
                    if (!_canPass) {
                        $rootScope.loading = false;
                        return;
                    }
                    Tests.store(_obj).then(function () {
                        $('#newCourseModal').modal('hide');
                        $('.modal-open').removeClass('modal-open');
                        $('.modal-backdrop.fade.in').remove();
                        $rootScope.loading = false;
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        setTimeout(function () {
                            SweetAlert.swal('Success', 'Tests created', 'success');
                        }, 400);
                    }, function (err) {
                        $('#newCourseModal').modal('hide')
                        $rootScope.loading = false;
                        setTimeout(function () {
                            SweetAlert.swal('Error', JSON.stringify(err), 'error');
                        }, 400);
                    });
                } else {
                    $('#newCourseModal').modal('hide')
                    setTimeout(function () {
                        SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                    }, 400);
                }
            });
        }
    }
})();
