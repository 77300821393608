(function() {
  'use strict';

  angular.module('backoffice.slides', [])
    .config(configFunction);

  function configFunction($stateProvider) {
    $stateProvider
      .state('admin.slides', {
        url: '/courses/:id/slides',
        templateUrl: 'slides/views/slides.html',
        controller: 'SlidesCtrl',
        controllerAs: 'vm',
                resolve: {
                    objDataResolve: getAllData
                }

      });
  }
  function getAllData($log, $rootScope, $stateParams, Slides, SweetAlert) {

        var vm = this;
        vm.myid = $stateParams.id;
        $rootScope.loading = true;

        vm.itemsPerPage = Slides.getNavItemsPerPage();

        var obj = {
            pagination: {
                start: 0,
                totalItemCount: 0,
                number: vm.itemsPerPage
            },
            group: "active",
            sort: {},
            search: {}
        }

        return Slides.getPage(obj,vm.myid)
            .then(function (resp) {
                $rootScope.loading = false;
                return resp.data.data;
            }, function (error) {
                $log.info("error orders");
                $rootScope.loading = false;
                SweetAlert.swal('Errore', 'Error in donwload the data', 'error');
            });

    }
})();
