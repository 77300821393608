(function () {
    'use strict';

    angular.module('backoffice.services')
        .factory('Course', coursesService);

    function coursesService($http) {
        var _service = {
            getAll: getAllFunction,
            getAllActive: getAllActiveFunction,
            store: storeFunction,
            delete: deleteFunction,
            changeStatus: changeStatusFunction,
            getPage: getPageFunction,
            edit: editFunction,
            getAllCount: getAllCountFunction,
            getDistinct: getDistinctFunction,
            getNavItemsPerPage:getNavItemsPerPage
        };

        return _service;

        function getAllFunction() {
            return $http.get('/backoffice/v1/course');
        }

        function getAllActiveFunction() {
            return $http.get('/backoffice/v1/course/active');
        }

        function storeFunction(course) {
            return $http.post('/backoffice/v1/course', course);
        }

        function deleteFunction(course) {
            return $http.delete('/backoffice/v1/course/' + course._id);
        }

        function changeStatusFunction(course) {
            return $http.put('/backoffice/v1/course/' + course._id);
        }

        function getPageFunction(info) {
            return $http.post('/backoffice/v1/course/getpage', info);
        }

        function editFunction(categories) {
            return $http.put('/backoffice/v1/course', categories);
        }

        function getAllCountFunction() {
            return $http.get('/backoffice/v1/course/count');
        }

        function getDistinctFunction() {
            return $http.get('/backoffice/v1/course/distinct');
        }
         function getNavItemsPerPage() {
            return 10;
        }

    }
})();