(function() {
  'use strict';

  angular.module('backoffice.services')
    .factory('Installations', installationsService);

  function installationsService($http) {
    var _service = {
      getAll: getAll,
      getPage: getPage,
      getDistinct: getDistinct,
      updatePassword: updatePassword,
      registrations: registrations,
      registrationsH: registrationsH,
      registrationsW: registrationsW,
      getDevice: getDevice,
      getVersionAndroid: getVersionAndroid,
      getVersionIos: getVersionIos,
      getPushActive: getPushActive,
      getNavItemsPerPage:getNavItemsPerPage
    };
    return _service;



    function getAll() {
      return $http.get('/backoffice/v1/installations');
    }

    function getPage(info) {
      return $http.post('/backoffice/v1/installations/getpage', info);
    }

    function getDistinct() {
      return $http.get('/backoffice/v1/installations/distinct');
    }

    function updatePassword(data) {
      return $http.put('/backoffice/v1/installations/updatePassword', data);
    }

    function registrations() {
      return $http.get('/backoffice/v1/installations/registrations');
    }

    function registrationsH() {
      return $http.get('/backoffice/v1/installations/registrations/hourly');
    }

    function registrationsW() {
      return $http.get('/backoffice/v1/installations/registrations/weekly');
    }

    function getDevice() {
      return $http.get('/backoffice/v1/installations/device');
    }

    function getVersionAndroid() {
      return $http.get('/backoffice/v1/installations/version/android');
    }

    function getVersionIos() {
      return $http.get('/backoffice/v1/installations/version/ios');
    }

    function getPushActive() {
      return $http.get('/backoffice/v1/installations/push');
    }

     function getNavItemsPerPage() {
            return 10;
        }

  }
})();
