(function() {
  'use strict';
  angular.module('backoffice.base')
    .filter('showYesNo', showYesNo);

  function showYesNo() {
    return function(input, uppercase) {
      if (input) return 'Yes'
      else return 'No'
    };
  }
})();
