(function() {
  'use strict';

  angular.module('backoffice.installations', [])
    .config(configFunctionInstallations);

  function configFunctionInstallations($stateProvider) {
    $stateProvider
      .state('user.installations', {
        url: '/installations',
        templateUrl: 'installations/views/installations.html',
        controller: 'InstallationsCtrl',
        controllerAs: 'vm',
                resolve: {
                    objDataResolve: getAllData
                }
      }).state('user.installations-detail', {
        url: '/installations/detail/:id',
        templateUrl: 'installations/views/user_detail.html',
        controller: 'InstallationsDetailCtrl',
        controllerAs: 'vm'
      });
  }

  function getAllData($log, $rootScope, $stateParams, Installations, SweetAlert) {

        var vm = this;

        $rootScope.loading = true;

        vm.itemsPerPage = Installations.getNavItemsPerPage();

        var obj = {
            pagination: {
                start: 0,
                totalItemCount: 0,
                number: vm.itemsPerPage
            },
            group: "active",
            sort: {},
            search: {}
        }

        return Installations.getPage(obj)
            .then(function (resp) {
                $rootScope.loading = false;
                return resp.data.data;
            }, function (error) {
                $log.info("error orders");
                $rootScope.loading = false;
                SweetAlert.swal('Errore', 'Error in donwload the data', 'error');
            });

    }
})();
