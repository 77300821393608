'use strict';

(function () {

    angular.module('backoffice.components')
        .directive('stPaginator', stPaginatorFn);

    stPaginatorFn.$inject = [];

    function stPaginatorFn() {

        var directive = {
            restrict: "E",
            templateUrl: "components/commons/stPaginator/stPaginator.directive.tpl.html",
            scope: {
                totalPages : "=",
                curPage : "=",
                itemsDisplayed: "=",
                onChangePage : "&",
                accessor: "="
            },
            link: linkFunc,
            controller: stPaginatorController,
            controllerAs: "stPaginatorCtrl",
            bindToController: true
        };

        function linkFunc(scope, el, attr, ctrl) {


        }

        return directive;
    }

    stPaginatorController.$inject = ['$log'];

    function stPaginatorController($log) {

             var self = this;

            

            //self.accessor = {};

            //self.totalPages = self.item.totalPages;
            //self.pages = _.range(0, self.totalPages);
            //$log.info("total " + self.totalPages);
            //$log.info(self.pages);

            self.gotoPage = gotoPage;
            self.gotoFirstPage = gotoFirstPage;
            self.gotoLastPage = gotoLastPage;
            self.gotoPrevPage = gotoPrevPage;
            self.gotoNextPage = gotoNextPage;

            //self.accessor.reset = reset;

            init();
            
            function init() {
                self.curPage = 0;
                //self.itemsDisplayed = self.totalPages > self.itemsDisplayed ? self.itemsDisplayed : self.totalPages;
                updatePaginatorView();

                $log.info("init " + self.curPage);
            }

            function gotoPage(p_page, p_event){
                self.curPage = p_page;
                broadcastCurPage(p_event);
            }
            function gotoFirstPage(p_event){
                self.curPage = 0;
                broadcastCurPage(p_event);
            }
            function gotoLastPage(p_event){
                self.curPage = self.totalPages-1;
                broadcastCurPage(p_event);
            }
            function gotoPrevPage(p_event){
                self.curPage = self.curPage - 1;
                broadcastCurPage(p_event);
            }
            function gotoNextPage(p_event){
                if(self.curPage+1 < self.totalPages) {
                    self.curPage = self.curPage + 1;
                    broadcastCurPage(p_event);
                }
            }

            function broadcastCurPage(p_event){
                self.onChangePage({curPage: self.curPage});
                updatePaginatorView();

                p_event.preventDefault();
                p_event.stopPropagation();
            }

            function reset() {
                self.curPage = 0;
                self.onChangePage({curPage: 0});
                // updatePaginatorView();
            }

            function updatePaginatorView() {
              self.pages = getRange();
              $log.info("updatePaginatorView " + self.pages.length );
            }

            function getRange() {
              var halfWay = Math.ceil(self.itemsDisplayed / 2);
              var pages = [];
              var i = 0;
              while (i < self.totalPages && i <= self.itemsDisplayed-1) {
                var pageNumber = calculatePageNumber(i, self.curPage, self.itemsDisplayed, self.totalPages);
                pages.push(pageNumber);
                i++;
              }
              return pages;
            }

            function calculatePageNumber(i, currentPage, paginationRange, totalPages) {
                var halfWay = Math.ceil(paginationRange / 2);
                if (i === paginationRange) {
                    return totalPages;
                } else if (paginationRange < totalPages) {
                    if (totalPages - halfWay < currentPage) {
                        return totalPages - paginationRange + i;
                    } else if (halfWay < currentPage) {
                        return currentPage - halfWay + i;
                    } else {
                        return i;
                    }
                } else {
                    return i;
                }
            }

    }

})();