(function () {
    'use strict';
    angular.module('backoffice.services')
        .factory('Tracks', tracksService);

    function tracksService($http) {
        var _service = {
            getAll: getAllFunction,
            getRecent: getRecentFunction,
            getPage: getPageFunction,
            getNavItemsPerPage: getNavItemsPerPage
        };
        return _service;

        function getAllFunction() {
            return $http.get('/backoffice/v1/tracks');
        }

        function getRecentFunction() {
            return $http.get('/backoffice/v1/tracks/recent');
        }

        function getPageFunction(info, id) {
            info._myid = id;
            return $http.post('/backoffice/v1/tracks/getpage', info);
        }

        function getNavItemsPerPage() {
            return 10;
        }
    }
})();