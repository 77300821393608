(function() {
  'use strict';

  angular.module('backoffice.pushes', [])
    .config(configFunctionPushes);

  function configFunctionPushes($stateProvider) {
    $stateProvider
      .state('admin.pushes', {
        url: '/pushes',
        templateUrl: 'pushes/views/pushes.html',
        controller: 'PushesCtrl',
        controllerAs: 'vm'
      }).state('admin.pushes-detail', {
        url: '/pushes/detail/:id',
        templateUrl: 'pushes/views/detail_push.html',
        controller: 'PushesDetailCtrl'
      });
  }
})();
