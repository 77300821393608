(function () {
    'use strict';

    angular.module('backoffice.services')
        .factory('Users', usersService);

    function usersService($http) {
        var _service = {
            getAll: getAll,
            getPage: getPage,
            getDistinct: getDistinct,
            updatePassword: updatePassword,
            registrations: registrations,
            registrationsH: registrationsH,
            registrationsW: registrationsW,
            getFrom: getFrom,
            getOne: getOne,
            getEmailActive: getEmailActive,
            getAllActive: getAllActive,
            getCsv: getCsv,
            getNavItemsPerPage: getNavItemsPerPage,
            activeUser: activeUser
        };
        return _service;



        function getAll() {
            return $http.get('/backoffice/v1/users');
        }

        function getOne(_id) {
            return $http.get('/backoffice/v1/users/detail/' + _id);
        }

        function getAllActive() {
            return $http.get('/backoffice/v1/users/active');
        }

        function getPage(info) {
            return $http.post('/backoffice/v1/users/getpage', info);
        }

        function getDistinct() {
            return $http.get('/backoffice/v1/users/distinct');
        }

        function updatePassword(data) {
            return $http.put('/backoffice/v1/users/updatePassword', data);
        }

        function registrations() {
            return $http.get('/backoffice/v1/users/registrations');
        }

        function registrationsH() {
            return $http.get('/backoffice/v1/users/registrations/hourly');
        }

        function registrationsW() {
            return $http.get('/backoffice/v1/users/registrations/weekly');
        }

        function getFrom() {
            return $http.get('/backoffice/v1/users/from');
        }

        function getEmailActive() {
            return $http.get('/backoffice/v1/users/email');
        }

        function getCsv() {
            return $http.get('/backoffice/v1/users/dw-csv');
        }

        function getNavItemsPerPage() {
            return 10;
        }

        function activeUser(id, banned) {
            return $http.post('/backoffice/v1/users/active/' + id + '/' + banned + '', {});
        }
    }
})();