(function() {
  'use strict';
  angular.module('backoffice.services')
    .factory('AuthToken', authTokenService);

  function authTokenService($window) {

    var _service = {
      getToken: getToken,
      getUser: getUser,
      setToken: setToken,
      setUser: setUser
    };
    return _service;



    function getToken() {
      return $window.localStorage.getItem('token');
    }

    function getUser() {
      return JSON.parse($window.localStorage.getItem('user'));
    }

    function setToken(token) {
      if (token)
        $window.localStorage.setItem('token', token);
      else
        $window.localStorage.removeItem('token');
    }

    function setUser(user) {
      if (user)
        $window.localStorage.setItem('user', JSON.stringify(user));
      else
        $window.localStorage.removeItem('user');
    }
  }
})();
