(function() {
  'use strict';

  angular.module('backoffice.services')
    .factory('Pushes', pushesService);

  function pushesService($http) {
    var _service = {
      getAll: getAllFunction,
      getOne: getOneFunction,
      send: sendFunction,
      getPage: getPageFunction
    };

    return _service;

    function getAllFunction() {
      return $http.get('/backoffice/v1/pushes');
    }

    function getOneFunction(id) {
      return $http.get('/backoffice/v1/pushes/' + id);
    }

    function sendFunction(push) {
      return $http.post('/backoffice/v1/pushes/send', push);
    }

    function getPageFunction(info) {
      return $http.post('/backoffice/v1/pushes/getpage', info);
    }
  }
})();
