(function() {
  'use strict';

  angular.module('backoffice.pushes')
    .controller('PushesCtrl', pushesCtrl);

  function pushesCtrl($rootScope, $location, Pushes, $filter, $state, SweetAlert) {
    var vm = this;

    vm.itemsByPage = 10;
    vm.currentPage = 0;
    vm._doit = false;
    vm.tableStateSaved = {};
    vm.tableStateSaved.pagination = {};
    vm.tableStateSaved.pagination.start = 0;
    vm.tableStateSaved.pagination.totalItemCount = 0;
    vm.tableStateSaved.pagination.number = vm.itemsByPage;
    vm.tableStateSaved.sort = {};
    vm.tableStateSaved.search = {};
    vm.makeRequest = makeRequestFunction;
    vm.saveQuery = saveQueryFunction;
    vm.getPage = getPageFunction;
    vm.sendMassive = sendMassivePush;

    $rootScope.makePagination = makePaginationFunction;
    $rootScope.loading = true;

    getPageFunction();

    function sendMassivePush() {
      SweetAlert.swal({
        title: 'Confirm sending?',
        text: 'You are going to send a massive push notification to all users.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#5CB85C',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      }, function(isConfirm) {
        if (isConfirm) {
          $rootScope.loading = true;
          Pushes.send({
            message: vm.message
          }).then(function(resp) {
            $rootScope.loading = false;
            $state.go($state.current, {}, {
              reload: true
            });
            SweetAlert.swal('Success', 'Notification sent.', 'success');
          }, function(err) {
            $rootScope.loading = false;
            SweetAlert.swal('Error', err.message, 'error');
          });
        } else {
          SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
        }
      });
    }


    function makeRequestFunction() {
      vm._doit = true;
    }

    function saveQueryFunction(tableState) {
      vm.tableStateSaved = tableState;
      if (vm._doit) {
        vm.getPage();
        vm._doit = false;
      }
    }

    function getPageFunction() {
      $rootScope.loading = true;
      Pushes.getPage(vm.tableStateSaved).then(function(resp) {
        $rootScope.loading = false;
        vm.info = resp.data.data.result;
        vm.total = resp.data.data.total;
        var toCeil = resp.data.data.total / vm.itemsByPage;
        vm.tableStateSaved.pagination.numberOfPages = Math.ceil(toCeil);
      }, function(err) {
        $rootScope.loading = true;
        $rootScope.error = 'Failed to fetch push';
      });
    }

    function makePaginationFunction(page) {
      $rootScope.currentPage = page;
      page--;
      vm.tableStateSaved.pagination.start = (page * vm.itemsByPage);
      vm.getPage();
    }

  }

})();
