(function () {

    'use strict';

    angular.module('backoffice.slides')
        .controller('SlidesCtrl', slidesCtrl);

    function slidesCtrl(objDataResolve, $rootScope, $location, Slides, $stateParams, $filter, SweetAlert, $state, Upload, envService, $timeout) {
        var vm = this;

        vm.itemsByPage = 10;
        vm.currentPage = 0;
        vm.total = objDataResolve.total;
        vm.numberOfPages = Math.ceil(vm.total / vm.itemsByPage);
        vm._doit = false;
        vm.myid = $stateParams.id;
        vm.tableStateSaved = {};
        vm.tableStateSaved.pagination = {};
        vm.tableStateSaved.pagination.start = 0;
        vm.tableStateSaved.pagination.totalItemCount = 0;
        vm.tableStateSaved.pagination.number = vm.itemsByPage;
        vm.tableStateSaved.sort = {};
        vm.tableStateSaved.search = {};
        vm.makeRequest = makeRequestFunction;
        vm.saveQuery = saveQueryFunction;
        vm.getPage = getPageFunction;
        vm.deleteSlides = deleteSlidesFunction;
        vm.changeState = changeStateFunction;
        vm.storeSlides = storeSlidesFunction;
        vm.editSlides = editSlidesFunction;
        vm.selectRow = selectRowFunction;
        vm.type = 'only_text';

        $rootScope.makePagination = makePaginationFunction;
        $rootScope.loading = true;
        $rootScope.page_title = 'Slides';

        getPageFunction();

        function makeRequestFunction() {
            vm._doit = true;
        }

        function saveQueryFunction(tableState) {
            vm.tableStateSaved = tableState;
            if (vm._doit) {
                vm.getPage();
                vm._doit = false;
            }
        }

        function selectRowFunction(row) {
            vm.selected = row;
        }

        function getPageFunction(currentPage) {
            $rootScope.loading = true;
            vm.currentPage = currentPage ? currentPage : 0;
            vm.tableStateSaved.pagination = {
                start: currentPage * vm.itemsByPage,
                number: vm.itemsByPage
            }
            Slides.getPage(vm.tableStateSaved, vm.myid).then(function (resp) {
                $rootScope.loading = false;
                vm.info = resp.data.data.result;
                vm.total = resp.data.data.total;
                var toCeil = resp.data.data.total / vm.itemsByPage;
                vm.tableStateSaved.pagination.numberOfPages = Math.ceil(toCeil);
                vm.numberOfPages = Math.ceil(toCeil);
            }, function () {
                $rootScope.loading = true;
                $rootScope.error = 'Failed to fetch push';
            });
        }

        function makePaginationFunction(page) {
            $rootScope.currentPage = page;
            page--;
            vm.tableStateSaved.pagination.start = (page * vm.itemsByPage);
            vm.getPage();
        }

        function deleteSlidesFunction(_id) {
            var data = {
                _id: _id
            };

            SweetAlert.swal({
                title: 'Are you sure?',
                text: 'The Slide will be deleted.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Confirm',
                closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    Slides.delete(data).then(function () {
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        SweetAlert.swal('Success', 'Slide deleted', 'success');
                    }, function (err) {
                        SweetAlert.swal('Error', err.message, 'error');
                    });
                } else {
                    SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                }
            });
        }

        function changeStateFunction(_id) {
            var data = {
                _id: _id
            };

            SweetAlert.swal({
                title: 'Are you sure?',
                text: 'The status will be changed.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Confirm',
                closeOnConfirm: true
            }, function (isConfirm) {
                if (isConfirm) {
                    Slides.changeStatus(data).then(function () {
                        $state.go($state.current, {}, {
                            reload: true
                        });
                        SweetAlert.swal('Success', 'Status changed', 'success');
                    }, function (err) {
                        SweetAlert.swal('Error', err.message, 'error');
                    });
                } else {
                    SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                }
            });

        }


        function storeSlidesFunction(file, file2, type) {
            SweetAlert.swal({
                'title': 'Confirm creation?',
                'text': 'A new Slide will be created.',
                'type': 'warning',
                'showCancelButton': true,
                'confirmButtonColor': '#5CB85C',
                'confirmButtonText': 'Confirm',
                'closeOnConfirm': true
            }, function (isConfirm) {
                if (isConfirm) {
                    $rootScope.loading = true;
                    if (file) {
                        console.log(file)
                        file.upload = Upload.upload({
                            url: envService.read('apiUrl') + '/backoffice/v1/slides_video',
                            data: {
                                title: vm.title,
                                text: vm.text,
                                type: vm.type,
                                file: file,
                                course: vm.myid,
                                thumbnail_upload: vm.thumbnail_upload
                            }
                        });
                        file.upload.then(function (response) {
                            $timeout(function () {
                                if (!type) {
                                    $rootScope.loading = false;
                                    $('.modal-open').removeClass('modal-open')
                                    $('.modal-backdrop.fade.in').remove();
                                    $state.go($state.current, {}, {
                                        reload: true
                                    });
                                    setTimeout(function () {
                                        SweetAlert.swal('Success', 'Slides created', 'success');
                                    }, 400);
                                } else {
                                    vm.title = undefined;
                                    vm.text = undefined;
                                    vm.type = 'only_text';
                                    vm.video = undefined;
                                    vm.thumbnail_upload = undefined;
                                    getPageFunction();
                                    setTimeout(function () {
                                        SweetAlert.swal('Success', 'Slides created', 'success');
                                    }, 100);
                                }
                            });
                        }, function (response) {
                            if (response.status > 0)
                                vm.errorMsg = response.status + ': ' + response.data;
                        }, function (evt) {
                            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                        });
                    } else {
                        Slides.store({
                            title: vm.title,
                            text: vm.text,
                            type: vm.type,
                            image: vm.image,
                            course: vm.myid
                        }).then(function () {
                            if (!type) {
                                $rootScope.loading = false;
                                $('.modal-open').removeClass('modal-open')
                                $('.modal-backdrop.fade.in').remove();
                                $state.go($state.current, {}, {
                                    reload: true
                                });
                                setTimeout(function () {
                                    SweetAlert.swal('Success', 'Slides created', 'success');
                                }, 400);
                            } else {
                                vm.title = undefined;
                                vm.text = undefined;
                                vm.type = 'only_text';
                                vm.video = undefined;
                                getPageFunction();
                                setTimeout(function () {
                                    SweetAlert.swal('Success', 'Slides created', 'success');
                                }, 100);
                            }
                        }, function (err) {
                            $rootScope.loading = false;
                            setTimeout(function () {
                                SweetAlert.swal('Error', JSON.stringify(err), 'error');
                            }, 400);
                        });


                    }

                } else {
                    setTimeout(function () {
                        SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                    }, 400);
                }
            });
        }

        function editSlidesFunction(file, type) {
            if (vm.selected.ogg) {
                var _message = 'the video will be upload.';
            }
            if (vm.selected.webm) {
                var _message = 'the video will be upload.';
            } else {
                var _message = 'the slide will be edited.';
            }
            SweetAlert.swal({
                'title': 'Confirm creation?',
                'text': _message,
                'type': 'warning',
                'showCancelButton': true,
                'confirmButtonColor': '#5CB85C',
                'confirmButtonText': 'Confirm',
                'closeOnConfirm': true
            }, function (isConfirm) {
                if (isConfirm) {
                    $rootScope.loading = true;
                    if (file) {
                        var _data = {
                            _id: vm.selected._id,
                            title: vm.selected.title,
                            text: vm.selected.text,
                            type: vm.selected.type,
                            url: vm.selected.url,
                            url_ogg: vm.selected.url_ogg,
                            url_webm: vm.selected.url_webm,
                            thumbnail: vm.selected.thumbnail,
                            course: vm.myid,
                            file: file,
                            thumbnail_upload: vm.selected.thumbnail_upload
                        };
                        if (vm.selected.ogg) {
                            _data.subtype = 'url_ogg'
                        }
                        if (vm.selected.webm) {
                            _data.subtype = 'url_webm'
                        }
                        if (vm.selected.video) {
                            _data.subtype = 'url'
                        }
                        file.upload = Upload.upload({
                            url: envService.read('apiUrl') + '/backoffice/v1/slides_video_edit',
                            data: _data
                        });
                        file.upload.then(function (response) {
                            $timeout(function () {
                                if (!type) {
                                    $rootScope.loading = false;
                                    $('.modal-open').removeClass('modal-open')
                                    $('.modal-backdrop.fade.in').remove();
                                    $state.go($state.current, {}, {
                                        reload: true
                                    });
                                    setTimeout(function () {
                                        SweetAlert.swal('Success', 'Slides edited', 'success');
                                    }, 400);
                                } else {
                                    vm.title = undefined;
                                    vm.text = undefined;
                                    vm.type = 'only_text';
                                    vm.video = undefined;
                                    vm.thumbnail_upload = undefined;
                                    getPageFunction();
                                    setTimeout(function () {
                                        SweetAlert.swal('Success', 'Slides edited', 'success');
                                    }, 100);
                                }
                            });
                        }, function (response) {
                            if (response.status > 0)
                                vm.errorMsg = response.status + ': ' + response.data;
                        }, function (evt) {
                            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                        });
                    } else {
                        Slides.edit({
                            _id: vm.selected._id,
                            title: vm.selected.title,
                            text: vm.selected.text,
                            type: vm.selected.type,
                            image: vm.selected.image,
                            course: vm.myid
                        }).then(function () {
                            if (!type) {
                                console.log("ciao")
                                $rootScope.loading = false;
                                $state.go($state.current, {}, {
                                    reload: true
                                });
                                setTimeout(function () {
                                    $('.modal-open').removeClass('modal-open')
                                    $('.modal-backdrop.fade.in').remove();
                                    SweetAlert.swal('Success', 'Slides edited', 'success');
                                }, 400);
                            } else {
                                vm.title = undefined;
                                vm.text = undefined;
                                vm.type = 'only_text';
                                vm.video = undefined;
                                getPageFunction();
                                setTimeout(function () {
                                    SweetAlert.swal('Success', 'Slides edited', 'success');
                                }, 100);
                            }
                        }, function (err) {
                            $rootScope.loading = false;
                            setTimeout(function () {
                                SweetAlert.swal('Error', JSON.stringify(err), 'error');
                            }, 400);
                        });


                    }

                } else {
                    setTimeout(function () {
                        SweetAlert.swal('Cancelled', 'Operation cancelled', 'error');
                    }, 400);
                }
            });
        }
    }
})();