(function() {
  'use strict';
  angular.module('backoffice.base')
    .controller('LoginCtrl', loginCtrl);

  function loginCtrl($rootScope, $location, $window, Auth, SweetAlert) {
    var vm = this;

    vm.login = loginFunction;
    vm.loginOauth = loginOauthFunction;

    function loginFunction() {
      $rootScope.loading = true;
      Auth.login({
        'email': vm.email,
        'password': vm.password
      }, function() {
        $rootScope.loading = false;
        $location.path('/');
      }, function() {
        $rootScope.loading = false;
        SweetAlert.swal('Error', 'Failed to login', 'error');
      });
    }

    function loginOauthFunction(provider) {
      $window.location.href = '/auth/' + provider;
    }
  }
})();
