(function () {
    'use strict';
    angular.module('backoffice', [
    'ui.router',
    'backoffice.services',
    'backoffice.base',
    'backoffice.users',
    'backoffice.installations',
    'backoffice.pushes',
    'backoffice.courses',
    'backoffice.categories',
    'backoffice.slides',
    'backoffice.tests',
    'backoffice.analitycs',
    'backoffice.components',
    'templates',
    'oitozero.ngSweetAlert',
    'angular.morris',
    'angularUtils.directives.dirPagination',
    'smart-table',
    'ui.bootstrap',
    'naif.base64',
    'colorpicker.module',
    'ngFileUpload',
    'environment'
  ]).config(configFunction);

    function configFunction($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, envServiceProvider) {

        envServiceProvider.config({
            domains: {
                development: ['localhost'],
                testing: ['admin.smartlearning.testing.server.iquii.info'],
                // pretesting: ['demo.getsmartlearning.com'],
                production: ['admin.be.getsmartlearning.it']
            },
            vars: {
                development: {
                    apiUrl: 'http://localhost:8920'
                },
                testing: {
                    apiUrl: 'http://api.smart-learning.testing.server.iquii.info'
                },
                /* pretesting: {
                    apiUrl: 'https://api.be.getsmartlearning.it'
                }, */
                production: {
                    apiUrl: 'https://api.be.getsmartlearning.it'
                }
            }
        });
        envServiceProvider.check();
        $urlRouterProvider.otherwise('404');
        $urlRouterProvider.rule(function ($injector, $location) {
            if ($location.protocol() === 'file')
                return;
            var path = $location.path(),
                search = $location.search(),
                params;
            if (path[path.length - 1] === '/') {
                return;
            }

            if (Object.keys(search).length === 0) {
                return path + '/';
            }

            params = [];
            angular.forEach(search, function (v, k) {
                params.push(k + '=' + v);
            });
            return path + '/?' + params.join('&');
        });

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });

        $httpProvider.interceptors.push(function ($q, $location, $window, apiRoot, envService) {
            return {
                'request': function (config) {
                    var token = $window.localStorage.getItem('token');
                    if (token)
                        config.headers['x-backoffice-token'] = token;
                    if (config.url.substr(0, 1) === '/')
                        config.url = envService.read('apiUrl') + config.url;
                    return config;
                },
                'responseError': function (response) {
                    if (response.status === 403 || response.status === 401) {
                        $window.localStorage.removeItem('token');
                        $window.localStorage.removeItem('role');
                        $location.path('/login');
                    }
                    return $q.reject(response);
                }
            };
        });
    }
})();
