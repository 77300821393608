(function () {
    'use strict';

    angular.module('backoffice.services')
        .factory('Tests', testsService);

    function testsService($http) {
        var _service = {
            getAll: getAllFunction,
            getAllActive: getAllActiveFunction,
            store: storeFunction,
            delete: deleteFunction,
            changeStatus: changeStatusFunction,
            getPage: getPageFunction,
            getNavItemsPerPage:getNavItemsPerPage
        };

        return _service;

        function getAllFunction() {
            return $http.get('/backoffice/v1/tests');
        }

        function getAllActiveFunction() {
            return $http.get('/backoffice/v1/tests/active');
        }

        function storeFunction(tests) {
            return $http.post('/backoffice/v1/tests', tests);
        }

        function deleteFunction(tests) {
            return $http.delete('/backoffice/v1/tests/' + tests._id);
        }

        function changeStatusFunction(tests) {
            return $http.put('/backoffice/v1/tests/' + tests._id);
        }

        function getPageFunction(info,id) {
            info._myid = id;
            return $http.post('/backoffice/v1/tests/getpage', info);
        }
         function getNavItemsPerPage() {
            return 10;
        }
    }
})();
